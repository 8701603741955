<template>
  <div class="w-100 transition">
    <GenericReport
      title="Relatório de Empréstimos"
      :route="route"
      :fields="fields"
      :topFilters="topFilters"
      :sideFilters="sideFilters"
      tipoRelatorio="relatorioEmprestimos"
    />
  </div>
</template>

<script>
import moment from 'moment';

import GenericReport from '@/components/Table/GenericReport';

export default {
  name: 'relatorioEmprestimos',
  components: {
    GenericReport,
  },

  data() {
    return {
      route: 'report/emprestimos',
      fields: [
        {
          label: 'Tipo de Material',
          key: 'Tipo de Material',
        },
        {
          label: 'ID',
          key: 'ID',
        },
        {
          label: 'Descrição',
          key: 'Descrição',
        },
        {
          label: 'Fornecedor',
          key: 'Fornecedor',
        },

        {
          label: 'Data/Hora',
          key: 'Data/Hora',
        },
        {
          label: 'Usuário',
          key: 'Usuário',
        },
        {
          label: 'Unidade',
          key: 'Unidade',
        },
        {
          label: 'Protocolo',
          key: 'Protocolo',
        },
        {
          label: 'Unidade Destino',
          key: 'Unidade Destino',
        },
        {
          label: 'Setor Destino',
          key: 'Setor Destino',
        },
        {
          label: 'Devolução',
          key: 'status',
        },
        {
          label: 'Justificativa',
          key: 'justificativa',
        },
      ],
      topFilters: {
        id_unidade: {
          type: 'genericSelect',
          route: 'unidade',
          defaultValue: null,
          firstOption: {
            value: null,
            label: 'Unidade',
          },
        },
        id_tipo_material: {
          type: 'genericSelect',
          route: 'tipoMaterial',
          defaultValue: null,
          firstOption: {
            value: null,
            label: 'Tipo de Material',
          },
        },
        date: {
          type: 'rangeDate',
          defaultInitDate: moment().subtract(7, 'days'),
          defaultEndDate: moment(),
        },
        id_unidade_destino: {
          type: 'genericSelect',
          route: 'unidade',
          defaultValue: null,
          firstOption: {
            value: null,
            label: 'Unidade de destino',
          },
        },
        statusAcao: {
          type: 'select',
          firstOption: {
            value: null,
            label: 'Status',
          },
          options: [{
            value: 'pendente',
            text: 'Pendente',
          }, {
            value: 'finalizado',
            text: 'Finalizado',
          }],
        },
      },
      sideFilters: {
        id: {
          type: 'input',
          label: 'ID',
        },
        descricao: {
          type: 'input',
          label: 'Descrição',
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition {
  transition: 0.3s ease-in-out;
}

#report h2 {
  margin-bottom: 30px;
}

#report table {
  background-color: #FFF;
}

#report table thead th {
  color: #7E829F;
  padding-bottom: 50px;
  font-weight: 900;
}

</style>
